import React from 'react';
import {
  TextField,
  BooleanField,
  DateField,
  useRecordContext,
} from 'react-admin';
import RolesField from './RolesField';

export function userOptionText(user, translate) {
  console.log('user', user);
  switch (true) {
    case !!user.email:
      return `${translate('resources.users.fields.email')} - ${user.email}`;
    case !!user.nfc:
      return `${translate('resources.users.fields.nfc')} - ${user.nfc}`;
    case !!user.pos:
      return `${translate('resources.users.fields.pos')} - ${user.pos}`;
    default:
      return translate('resources.users.unidentified');
  }
}

export const EmailOrNfcField = () => {
  const record = useRecordContext();
  if (record && record.email) return <TextField key="email" source="email" />;
  return <TextField key="nfc" source="nfc" />;
};

export default [
  <TextField key="email" source="email" />,
  <TextField key="nfc" source="nfc" />,
  <TextField key="pos" source="pos" />,
  <BooleanField key="enabled" source="enabled" />,
  <RolesField key="roles" source="roles" sortable={false} />,
  <DateField key="createdAt" source="createdAt" />,
];
